import axios, {formToJSON} from 'axios'


//const serverURL = 'http://localhost:3000'
// const serverURL = 'https://s2.click2meet.de:3000'
const serverURL = 'https://christiani-92b8.restdb.io/rest'

const config = {
    baseURL: `${serverURL}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'x-apikey': '66d690b73547b3c177f15875d7b435a5611be'
        'x-apikey': '6649fe0180d0b004d230cb4b'
    },
    timeout: 10000
}


//let originalUrl = null
let withAuth = false;

const apiClient = axios.create(config)

apiClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(withAuth) {
        console.log("in interceptor: "+`Bearer ${localStorage.getItem('wp_api_token')}`);
        config.headers["Authorization"] = `Bearer ${localStorage.getItem('wp_api_token')}`;
    }
    return config;
});

export default {
    async login(user, password) {

        return apiClient.get('/gpt-kunden?q={"user":"'+user+'", "password":"'+password+'"}').then(result => {
            console.log(result);
            if(result.data.length > 0){
                return result.data[0];
            }
            return false;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    /* Kunden */
    async getKunden(){
        return apiClient.get('/gpt-kunden').then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    /* Bots */
    async getBots(kunde_id = ""){
        let url = '/gpt-bots';
        if(kunde_id !== "") url = url + '?q={"kundeid":"'+kunde_id+'"}';
        return apiClient.get(url).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getBot(bot_id = ""){
        let url = '/gpt-bots';
        url = url + '?q={"_id":"'+bot_id+'"}';
        return apiClient.get(url).then(result => {
            if (result.data.length > 1) {
                throw new Error("More than one record found for the provided id");
            }
            if (result.data.length === 0) {
                throw new Error("No record found for the provided id");
            }
            return result.data[0]
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async updateBot(data){
        return apiClient.put('/gpt-bots/'+data._id, JSON.stringify(data)).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getKundeBots(kunde_id = ""){
        let url = '/gpt-kunde-gpt';
        if(kunde_id !== "") url = url + '?q={"kundeid":"'+kunde_id+'"}';
        return apiClient.get(url).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    /* Files */
    async getSelectedFiles(gpt){
        return apiClient.get('/gpt-files?q={"gptid":"'+gpt+'"}').then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async updateSelectedFiles(fileid, data){
        return apiClient.put('/gpt-files/'+fileid, data).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async createSelectedFiles(data){
        return apiClient.post('/gpt-files/', data).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    /* Blocks */
    async getBlocks(botid, type){
        let query = `{"botid":"${botid}"}`;

        if (type) {
            query = `{"botid":"${botid}", "type": "${type}"}`;
        }

        return apiClient.get('/gpt-blocks?q=' + query).then(result => {
            return result.data;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async updateBlock(data){
        return apiClient.put('/gpt-blocks/'+data._id, data).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async createBlock(data){
        return apiClient.post('/gpt-blocks', data).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async deleteBlock(bid){
        return apiClient.delete('/gpt-files/'+bid).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getSession(session) {
        return apiClient.get("/mm-sessions/"+session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },

    async createSession(session) {
        return apiClient.post("/mm-sessions", session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async updateSession(session) {
        return apiClient.put("/mm-sessions/"+session._id, session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async deleteSession(session) {
        return apiClient.delete("/mm-sessions/"+session).then(result => {
            console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
}
