<template>
  <v-card class="padded-card">
    <v-row>
      <v-col class="d-flex align-center">
        <v-card-title>{{ "S3-Medien GPT Admin" }} | {{ caseTitle && $te(caseTitle) ? $t(caseTitle) : "" }}</v-card-title><v-card-subtitle>{{ type && $t(type) }}</v-card-subtitle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex align-center justify-end">
        <v-spacer></v-spacer>
        <LanguageSwitcher></LanguageSwitcher> |
        <router-link v-if="!store.state.isLoggedIn" :to="{ name: 'Login' }">{{ $t('Login') }}</router-link>
        <button v-else @click="logout">{{ $t('Logout') }}</button>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import {useRoute } from "vue-router";
import { useStore } from 'vuex';
import {ref, watch} from "vue";
import router from "@/router";
//import APIServiceRestDB from "@/services/APIServiceRestDB";

export default {
  data() {
    return {
      caseTitle: ""
    }
  },
  components: {
    LanguageSwitcher
  },
  setup() {
    const store = useStore(); // Verwende useStore, um auf den Vuex Store zuzugreifen
    const type = ref('');
    const caseTitle = ref("");
    const route = useRoute();

    watch(() => route.name, (newRoute) => {
      updateTitle();
    });
    watch(() => route.params.type, (newRoute) => {
      updateTitle();
    });

    const updateTitle = () => {
      type.value = "";
      if(route.params.type && route.params.type !== ""){
        type.value = route.params.type;
      }
      caseTitle.value = route.name;
    };

    const logout = () => { // Logout-Funktion
      store.commit('logout', false); // Setze isLoggedIn auf false
      router.push({ name: 'Home' }); // Leite den Benutzer zur Home-Seite um
    };

    return { type, caseTitle, store, logout };
  },
}
</script>

<style scoped>

</style>
