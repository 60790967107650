<template>
  <Header />
  <div class="content">
    <aside class="aside">
      <ul class="menu">
        <li v-for="(item, index) in menuItems" :key="index">
          <router-link v-if="item.roles.length === 0 || (user && item.roles.includes(user.role))"
                       :to="item.destination">{{ item.name }}</router-link>
        </li>
      </ul>
    </aside>
    <div class="view">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import Header from '../views/HeaderView.vue';
import { watch } from 'vue';
import { useStore } from 'vuex';
import {ref} from "vue";
import i18n from "@/i18n";

export default {
  components: {
    Header,
  },
  setup() {
    const store = useStore();
    const menuItems = ref([]);
    let user = ref(store.state.user);
    const caseTitle = ref("-");

    watch(() => i18n.global, () => {
      updateMenuItems();
    }, { deep: true });

    watch(() => store.state, () => {
      user.value = store.state.user;
      updateMenuItems();
    }, { deep: true });

    function updateMenuItems(){
      menuItems.value = [
        { name: 'Home', roles: [], destination: 'Home' }, // Leere Liste von Rollen bedeutet, dass jeder diesen Menüeintrag sehen kann
        { name: i18n.global.t('Settings'), roles: [store.state.roles.admin], destination: { name: 'Settings' }},
        { name: i18n.global.t('Users'), roles: [store.state.roles.admin], destination: { name: 'Users'} },
        { name: 'Bots', roles: [store.state.roles.admin, store.state.roles.company], destination: { name: 'Bots'} },
        { name: i18n.global.t('Files'), roles: [store.state.roles.admin, store.state.roles.company], destination: { name: 'Files' }},
        { name: 'Admin', roles: [store.state.roles.admin], destination: { name: 'Blocks', params: { type: 'admin'  } }},
        { name: i18n.global.t('company'), roles: [store.state.roles.admin, store.state.roles.company], destination: { name: 'Blocks', params: { type: 'company' } }},
        { name: i18n.global.t('app'), roles: [store.state.roles.admin, store.state.roles.company, store.state.roles.app], destination: { name: 'Blocks', params: { type: 'app' } } },
        { name: i18n.global.t('chats'), roles: [store.state.roles.admin, store.state.roles.company, store.state.roles.app], destination: { name: 'Threads', params: { type: 'app' } } },
      ]
    }

    updateMenuItems();

    return { caseTitle, menuItems, user };
  },
};
</script>
