<script setup>
import store from "@/store";

console.log(store.state.isLoggedIn);
</script>

<template>
  <router-link :to="{ name: 'Login' }">{{ $t('Login') }}</router-link>
</template>

<style scoped>

</style>
