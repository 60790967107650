import {createWebHistory, createRouter} from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import store from '@/store';

const routes = [
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Home.vue'),

  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: '/admin',
    name: 'Admin',
    beforeEnter: (to, from, next) => {
      if (store.state.isLoggedIn) {
        next();
      } else {
        next({name: 'Login'}); // Leite zur Login-Seite um, oder wo immer du möchtest.
      }
    },
    children: [
      {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Settings.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.isLoggedIn && store.state.user.role === 'admin') {
            next();
          } else {
            next({name: 'Login'});
          }
        }
      },
      {
        path: '/bots',
        name: 'Bots',
        component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Bots.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.isLoggedIn && (store.state.user.role === 'admin' || store.state.user.role === 'company')) {
            next();
          } else {
            next({name: 'Login'});
          }
        }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Users.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.isLoggedIn && store.state.user.role === 'admin') {
            next();
          } else {
            next({name: 'Login'});
          }
        }
      },
      {
        path: '/files',
        name: 'Files',
        component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Files.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.isLoggedIn) {
            next();
          } else {
            next({name: 'Login'});
          }
        }
      },
      {
        path: '/threads',
        name: 'Threads',
        component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Threads.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.isLoggedIn) {
            next();
          } else {
            next({name: 'Login'});
          }
        }
      },
      {
        path: '/blocks/:type',
        name: 'Blocks',
        component: () => import(/* webpackChunkName: "unterseite1" */ '../views/Blocks.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.isLoggedIn) {
            next();
          } else {
            next({name: 'Login'});
          }
        }
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
