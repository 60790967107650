import { createStore } from 'vuex'

export default createStore({
    state: {
        isLoggedIn: !!localStorage.getItem('isLoggedIn'),
        roles: {
            admin: 'admin',
            company: 'company',
            app: 'app',
            user: 'user'
        },
        user: JSON.parse(localStorage.getItem('user')) || null
    },
    mutations: {
        login(state) {
            state.isLoggedIn = true
            localStorage.setItem('isLoggedIn', 'true')
        },
        logout(state) {
            state.isLoggedIn = false
            state.user = null
            localStorage.removeItem('isLoggedIn')
            localStorage.removeItem('user')
        },
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        }
    }
})
