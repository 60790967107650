<template>
  <div>
    <h2>Login</h2>
    <form @submit.prevent="checkLogin">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username" required>

      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required>

      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import store from "@/store";
import APIServiceRestDB from "@/services/APIServiceRestDB";

export default {
  setup() {
    const router = useRouter()

    const username = ref('')
    const password = ref('')

    const checkLogin = async () => {
      const userResponse = await APIServiceRestDB.login(username.value, password.value);
      if (userResponse) {
        // Login erfolgreich, setze isLoggedIn auf true im localStorage
        store.commit('login', true);
        console.log(userResponse);
        store.commit('setUser', userResponse);
        await router.push({name: 'Admin'})
      } else {
        alert("Invalid login")
      }
    }

    return {
      username,
      password,
      checkLogin
    }
  }
}
</script>
